import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "techniques",
    path: "accueil / nos métiers / techniques / administrateur système réseau"
  },
  title: "Technicien Support",
  key_skills: [
    {
      text: "Etre consciencieux"
    },
    {
      text: "Analyser etComprendre"
    },
    {
      text: "Contrôler"
    }
  ],
  prerequisites:[
    {
      text: "Maitrise de l’environnement serveur et des matériels ou logiciels associés"
    },
    {
      text: "Connaissances dans les environnements messagerie, bases de données, stockage…"
    },
    {
      text: "Anglais élémentaire"
    },
    {
      text: "Bac+2 informatique ou une expérience de deux ans dans le métier et certification MCP serveur."
    }
  ],
  missions:[
    {
      text: "Maintenir opérationnelles les infrastructures système et / ou réseau"
    },
    {
      text: "Superviser le fonctionnement des infrastructures et assurer leur sécurité"
    },
    {
      text: "Mettre en œuvre les évolutions de service"
    },
    {
      text: "Appliquer les procédures d’exploitation et de maintenance"
    }
  ],
  activities:[
    {
      text: "Superviser les infrastructures exploitées"
    },
    {
      text: "Contrôler au quotidien les fonctionnalités  opérationnelles et le respect des règles de sécurité"
    },
    {
      text: "Assurer une veille technologique et remonter tout dysfonctionnement"
    },
    {
      text: "Prendre en charge le support N2-N3"
    },
    {
      text: "Mettre en œuvre les évolutions du système ou de l’architecture"
    }
  ]
}

const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;